<template>
	<div id="aqt_one">
		<div v-for="(item,index) in dataList" :key="index" class="aqt_one">
			<div class="aqt_one1" @click="oneClick(item,index)">
				<div :style="{color:index==selectOne ? '#003461' : ''}" class="aqt_one_center aqt_js_dd"><p class="aqt_lang_mn titlefont aqt_js_pp">{{item.title|U2M}}</p></div>
				<div class="aqt_one_bottom">
					<img :src="require('./../assets/img/'+(index==selectOne ? 'san_open' : 'san_close') +'.png')" alt="">
				</div>
			</div>
			<transition name="slide-fade">
				<lay-mulu v-if="(index==selectOne ? (item.child.length ? true : false) : false)" :item="item.child" @on-child-click="onChildClick"></lay-mulu>
			</transition>
		</div>
	</div> 
</template>

<script>
	export default {
		components: {
			LayMulu: resolve => {require(['./LayMulu.vue'], resolve);},
		},
		props: {
			dataList: {
				type: [Array],
				default: [],
			},
		},
		data() {
			return {
				num:0,
				selectOne:-1,
			};
		},
		methods: {
			oneClick(item,index){
				let _this = this
				let nums = ++_this.num
				if(nums > 1 && _this.selectOne == index){
					_this.selectOne = -1
					_this.num = 0
				}else{
					_this.selectOne = index;
				}
				if(_this.selectOne != -1){
					_this.$emit('on-child-click',item,index);
				}
			},
			onChildClick(item,index){
				if(this.selectOne != -1){
					this.$emit('on-child-click',item,index);
				}
			},
			onWidth(){
				this.$nextTick(_=>{ 
					if(this.dataList.length){
						let ddarr = document.getElementsByClassName('aqt_js_pp')
						let ddarrs = document.getElementsByClassName('aqt_js_dd')
						console.log(7666666)
						for (let index = 0; index < ddarr.length; index++) {
						console.log(ddarr[index].clientWidth)
							// ddarrs[index].style.width = ddarr[index].clientWidth+'px'
						}
					}
				})
			},
		},
		// mounted(){
		// 		console.log(111)

		// 	this.onWidth()
		// },
		watch: {
			dataList: function (val) {
				setTimeout(function (){
				// console.log(666666)
				// this.onWidth()

				}, 3000);
			},
		}

	};
</script>


<style lang="less">
#aqt_one{
	height: 100%;
	color: #333333;
	font-size: 1.8rem;
	display: flex;
	line-height: 1.6rem;
	 overflow-x: scroll;
                        overflow-x: auto;
	.aqt_one{
		height: 100%;
		display: flex;
		.slide-fade-enter-active,.slide-fade-leave-active {
			transition: all .3s ease;
		}
		.slide-fade-enter, .slide-fade-leave-to{
			transform: translateX(10px);
			opacity: 0;
		}
		.aqt_one1{
			height: 100%;
			background-color: #ffffff;
			padding:0 1rem;
			margin-right:0.6rem;
			float: left;
			// width: 3rem;
			.aqt_one_center{
				height: calc(100% - 2.8rem);
				overflow: hidden;
				margin: 0.6rem auto 0;
				p{
					margin: 0 auto;
				}
			}
			.aqt_one_bottom{
				height: 1.6rem;
				img{
					height: 100%;
					display: block;
					margin: auto;
				}

			}
		}
	}
}

</style>
